import { css } from '@emotion/react'
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';

import { TitleIcon, ButtonDegrade, Oferta } from '@findep/mf-landings-core'
import { withThemeProps } from '@findep/microfronts-core'
import { Typography, Grid, Box, Snackbar } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import SimpleAccordion from '../elements/ControlledAccordions'
import ListItem from '../elements/SelectedListItem'
import Checkbox from '../elements/Checkboxes';


import EditIcon from '@material-ui/icons/Edit';
import { FileCopy } from '@material-ui/icons';
import format from 'date-fns/format'
import es from 'date-fns/locale/es'
import en from 'date-fns/locale/en-US'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { OfferReaccept } from '../../services/LandingV4/offerReaccept'
import { navigate } from "../../helpers/queryNavigate"
//SERVICIOS 
import { CataloguesService } from '../../services/LandingV4'

import { Firma as FirmaService } from '../../services/LandingV4/firma'
import formatNumber from '../../helpers/formatNumber'
import { getPeriodPlural } from '../../helpers/period'
import { iconStyle, h1Style, root } from '../../styles/lo-sentimos'
import { restoreFromSessionStorageAfiShort } from "../../components/pages/afi/restoreSessionStorage"

let firmaService = []

const locales = {
  es,
  en,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const mb = css`
  margin-bottom: 1rem;
`

const mx = css`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const secundaryText = css`
  padding-left: 1rem;
`

const iconRoot = css`
  color: rgba(0,0,0,0.26);
  font-size: 2.2rem !important;
`
const containerSelct = css`
  display: flex;
  flex-direction: column;
  width: 80% !important;
  text-align: justify;
`

const FirmaElectronica = (props) => {

  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [errorService, setErrorService] = useState(false);
  const [checkState, setCheckState] = useState(false);
  const [habilitaBoton, setHabilitaBoton] = useState(true);
  const [legalesGet, setlegalesGet] = React.useState([]);
  const [offerData, setOfferData] = useState()

  useEffect(() => {
    let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let originState = restoreFromSessionStorageAfiShort('afiStateShort')
    firmaService = new FirmaService(flujo?.creditApplicationId)

    async function getdata() {
      const catalogos = new CataloguesService()
      const catData = await catalogos.getLegalesLang(flujo.creditApplicationId, props?.lang, originState)
      setlegalesGet(catData.data.legales)

      const { data } = await firmaService.afiGetSignature()

      setOfferData(data)

    }
    try {
      getdata()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const handleChangeCheck = (event) => {
    setCheckState(event)
    if (event) { setHabilitaBoton(false) } else { setHabilitaBoton(true) }
  };

  const handleNextStep = async () => {
    setOpenBackDrop(true)


    let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    if (checkState) {
      try {

        const offerreac = new OfferReaccept(flujo.creditApplicationId)
        await offerreac.send()
        let { data } = await firmaService.afiPutSignature()
        navigate(props.pageContext.next.path)
      } catch (e) {
        console.error(e)
        setErrorService(true)
        setOpenBackDrop(false)
      }



    }

  }


  const {
    errorMessage,
    nextButtonText,
    title,
    contractTitle,
    contractBody,
    contractStrong,
    lang,
    offerTexts,
  } = props

  const fecha = offerData?.nextPayment && format(new Date(offerData?.nextPayment), 'PPP', { locale: locales[lang] })

  const datosOferta = [
    { icono: ArrowForwardIcon, titulo: offerTexts[0], texto: offerData && `${formatNumber(offerData?.amount || 0)}` },
    { icono: ArrowForwardIcon, titulo: offerTexts[1], texto: offerData && `${formatNumber(offerData?.payment || 0)}` },
    { icono: ArrowForwardIcon, titulo: offerTexts[2], texto: offerData && `${offerData?.terms} ${getPeriodPlural(offerData?.paymentPeriod, lang)}` },
    { icono: ArrowForwardIcon, titulo: offerTexts[3], texto: offerData && fecha },
  ]


  return (
    <Grid container justify="center">
      <Grid item xs={12} md={6} css={root}>
        <Grid container justify="center">
          <Box css={mx}>
            <div css={root}>
              <EditIcon css={iconStyle} />
              <Typography css={h1Style} component='span'>
                {title}
              </Typography>
            </div>
          </Box>
          {/* <Box css={mx}>
            <TitleIcon title={title} icon={EditIcon} textColor={true} />
          </Box>          */}
        </Grid>
        <Grid item xs={12} >
          <Oferta datos={datosOferta} colorOne="#FF9F01" colorTwo="#FA4616" />
        </Grid>
        <br />

        <Grid item xs={12} >
          <SimpleAccordion header expanded={false} titulo={<TitleIcon icon={FileCopy} title={contractTitle} />}>
            <Grid Container>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" css={secundaryText}>
                  <strong>
                    {contractStrong}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ListItem Legales={legalesGet} />
              </Grid>
            </Grid>
          </SimpleAccordion>
          <SimpleAccordion header={false}>
            <Grid Container>
              <Grid item xs={12}>
                <Grid container css={root}>
                  <Grid item xs={2} css={iconRoot} >
                    <Box display="flex" justifyContent="">
                      <Checkbox
                        id="firma-digital-paquete-disposicion-check"
                        name="firma-digital-paquete-disposicion-check"
                        check={checkState}
                        onChange={handleChangeCheck}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={10} css={containerSelct}>
                    {contractBody}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SimpleAccordion>
          <Grid containre>
            <Grid item xs={12}>
              <br />
              <Box display="flex" justifyContent="flex-end">
                <ButtonDegrade
                  id="firma-electronica-continuar-button"
                  disabled={habilitaBoton}
                  icon={ArrowForwardIcon}
                  textButton={nextButtonText}
                  onClick={handleNextStep}
                />
              </Box>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={openBackDrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Snackbar open={errorService} autoHideDuration={6000} onClose={() => setErrorService(false)}>
        <Alert variant="filled" severity="warning" onClose={() => setErrorService(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

FirmaElectronica.defaultProps = {
  errorMessage: 'Lo sentimos, por el momento el servicio no se encuentra disponible',
  lang: 'es',
  title: 'Aceptar contrato',
  contractTitle: '¿Qué comprende mi contrato digital?',
  contractStrong: 'Tu contrato se compone de los siguientes documentos (el "Paquete de Disposición"):',
  contractBody: (
    <>
      <p>He leído los términos y condiciones de mi préstamo personal, estoy conforme y con mi firma he autorizado todos los documentos anteriormente enlistados (el "Paquete de Disposición").</p>
      <p>Al seleccionar y hacer clic en la casilla, certifico que estoy de acuerdo y acepto los términos y condiciones.</p>
    </>
  ),
  offerTexts: ['Monto:', 'Pago:', 'Plazo:', 'Primer pago:'],
  nextButtonText: 'SIGUIENTE'



}

export default withThemeProps(FirmaElectronica, 'VDNPFirmaElectronica');
